// import base from "../../../common/base";
export default {
  setShukkaZumiAnkenItemsMutations(state, payload) {
    const shukkaZumiAnkenItems = [];
    if (payload) {
      payload.map((item) => {
        shukkaZumiAnkenItems.push(item);
      });
    }
    state.shukkaZumiAnkenItems = shukkaZumiAnkenItems;
  },
  setCommentItemsMutations(state, payload) {
    const commentItems = [];
    if (payload) {
      payload.map((item) => {
        commentItems.push(item);
      });
    }
    state.commentItems = commentItems;
  },
};
