import { maruNum } from '@/consts.js';
export default {
  setKonpomeisai(store, payload) {
    store.commit('setKonpomeisai', payload);
  },

  setBoxTypes(store, boxTypes) {
    let types = [];
    boxTypes.forEach((box) => {
      types.push({
        boxName: box.box_name,
        value: box.box_type,
        boxTypeDim: `${maruNum[box.box_type - 1]}▼ ${box.dimension}cm`,
        boxDim: box.dimension,
        packaging_materials_weight: box.packaging_materials_weight,
      });
    });
    store.commit('setBoxTypes', types);
  },

  setInvoiceLists(store, invoiceLists) {
    let invoices = [];
    invoiceLists.forEach((invoice) => {
      invoices.push({
        invoiceId: invoice.id,
        matterNo: invoice.invoice_no,
        memberId: invoice.member_id,
        value: invoice.invoice_no,
        buyerName: invoice.member_name_ja,
        status: invoice.status,
        sipsMemberId: invoice?.sips_member_id ?? null,
      });
    });
    store.commit('setInvoiceLists', invoices);
  },

  setInvoiceDetails(store, invoiceDetails) {
    const details = invoiceDetails.map((invoiceDetail) => ({
      ...invoiceDetail,
      /**
       * 箱タイプを丸数字（①、②など）で表示する
       */
      box_type_lbl: maruNum[invoiceDetail.box_type - 1],
      /**
       * BOX充当率
       * - 梱包マスターに存在しないものにはエラーメッセージを表示する。
       * - センター権限者には充当率非表示（@see 課題191）
       */
      appropriation_rate:
        !invoiceDetail.box_weight || invoiceDetail.box_weight == 0 || !invoiceDetail.appropriation_rate
          ? store.rootGetters['defined/messages'].NO_DEVICE_INFO_IN_PACKING_MASTER
          : invoiceDetail.appropriation_rate,
      /**
       * 自動まとめが不可になった原因を表示するフラグ
       */
      error:
        !invoiceDetail.box_weight || invoiceDetail.box_weight == 0 || !invoiceDetail.appropriation_rate ? true : false,
      /**
       * キャンセルされたBOXは選択不能にするフラグ
       */
      isSelectable: invoiceDetail.delete_flag === 0 ? true : false,
    }));

    store.commit('setInvoiceDetails', details);
  },

  setPackingDetails(store, packingDetails) {
    store.commit('setPackingDetails', packingDetails);
    const autoConsolidateFailed = packingDetails.map(({ error }) => error).some((x) => x);
    if (autoConsolidateFailed) {
      const msg = packingDetails.map(({ error }) => error).filter((x) => x)[0];
      store.commit('ui/setErrorMessage', msg, { root: true });
    }
  },

  resetInvoiceDetails(store) {
    store.commit('resetInvoiceDetails');
  },
  resetPackingDetails(store) {
    store.commit('resetPackingDetails');
  },
};
