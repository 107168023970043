export default {
  setBoxTypes(state, payload) {
    state.boxTypes = payload.sort((a, b) => Number(a.boxType) - Number(b.boxType));
    state.editing = null;
  },

  changeDraftString(state, { key, value, index }) {
    state.boxTypes = state.boxTypes.map((b, i) => (i === index ? { ...b, [key]: value } : b));
  },
  changeDraftNumber(state, { key, value, index }) {
    state.boxTypes = state.boxTypes.map((b, i) => (i === index ? { ...b, [key]: Number(value) } : b));
  },

  startEditing(state, index) {
    if (!state.editing) {
      state.editing = { index, backup: state.boxTypes[index] };
    } else {
      const { editing } = state;
      state.boxTypes = state.boxTypes.map((b, i) => (i === editing.index ? editing.backup : b));
      state.editing = { index, backup: state.boxTypes[index] };
    }
  },
  cancelEditing(state) {
    const { index, backup } = state.editing;
    state.boxTypes = state.boxTypes.map((b, i) => (i === index ? backup : b));
    state.editing = null;
  },
};
