import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      regions: [],
      dashboardItems: [],
      checkAll: false,
      reactiveVar: {
        invoiceDate: [],
        nyukinDate: [],
        ankenNo: '',
        shukkayoteiDate: [],
        shukkaDate: [],
      },
      csvFlag: true,
    };
  },
  mutations,
  actions,
  getters,
};
