<template>
  <v-container fluid class="pt-0">
    <slot></slot>
    <v-data-table
      v-model="itemSelected"
      ref="table"
      class="resizable-column"
      :search="search"
      :headers="styledHeaders"
      :items="items"
      :item-class="itemClass"
      :sort-by="selected"
      :sort-desc="desc"
      :multi-sort="multiSort"
      :item-key="itemKey"
      :selectable-key="selectableKey"
      :show-select="showSelect"
      :hide-default-footer="hidePagination"
      :height="items.length >= 0 && items.length <= 8 ? 'auto' : $route.path !== '/konpomeisai' ? '75vh' : '50vh'"
      fixed-header
      :items-per-page="perPage"
      :footer-props="{ itemsPerPageOptions }"
      checkbox-color="primary"
      dense
      :loading="loading"
      :custom-sort="customSort"
      @update:sort-by="sortColSelect"
      @update:sort-desc="fixColsBySorting"
      @update:page="fixColsBySorting"
      @input="sendSelectedRows"
    >
      <!-- @pagination="getMaxTableHeight" -->
      <!-- Slot for Dashboard expansion table -->
      <template v-if="expansionTable" v-slot:body="{ items, headers }">
        <slot name="expansionTable" v-bind="{ rows: items, headers: headers }"></slot>
      </template>

      <!-- Slots for button/checkbox/edit diag .etc inside table rows -->

      <template v-for="col in columnName" v-slot:[col]="row">
        <slot :name="col" v-bind="row"></slot>
      </template>

      <template v-slot:[`header.reservation_number`]="row">
        <slot
          ><span>{{ row.header.text }} </span>
          <v-tooltip bottom color="red darken-2">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar v-show="hasNoReservationNumber" size="20" class="ml-1" v-bind="attrs" v-on="on">
                <v-icon color="red darken-1"> mdi-alert-circle </v-icon>
              </v-avatar>
            </template>
            <ul v-for="seq in seqWithNoReservationNumber" :key="seq">
              -
              {{
                seq
              }}
            </ul>
          </v-tooltip>
        </slot>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { resizableGrid } from './resizable.js';

export default {
  props: {
    columnName: Array || String,
    headers: Object,
    items: Array,
    itemKey: String,
    search: String,
    selectableKey: String,
    height: { type: Number || String, default: 'auto' },
    minWidth: { type: Number, default: 1200 },
    multiSort: { type: Boolean, default: false },
    showSelect: { type: Boolean, default: false },
    hidePagination: { type: Boolean, default: false },
    expansionTable: { type: Boolean, default: false },
    itemsPerPageOptions: { default: [10, 50, 100, -1] },
    perPage: { type: Number, default: 100 },
    itemClass: { type: Function, default: () => {} },
    hasNoReservationNumber: { type: Boolean, default: false },
    seqWithNoReservationNumber: { type: Array, default: () => [] },
  },
  data() {
    return {
      itemSelected: [],
      selected: [],
      desc: [],
      widths: null,
      /**
       * @type Array<Object>
       */
      tables: [],
    };
  },
  computed: {
    ...mapGetters({
      loading: 'ui/loading',
      sort: 'ui/sort',
      labels: 'defined/labels',
      messages: 'defined/messages',
    }),
    styledHeaders() {
      this.headers.forEach((header) => {
        header.class ? `${header.class} font-weight-black ` : (header.class = `px-1 font-weight-black `);
        header.align ? header.align : (header.align = 'center');
        header.width ? header.width : (header.width = 'auto');
        typeof header.cellClass === 'undefined'
          ? (header.cellClass = '--min-cell-width')
          : (header.cellClass += ' --min-cell-width');
      });
      return this.headers;
    },
    fixedColWidths() {
      return this.headers.map(({ fixedWidth }) => fixedWidth).filter((x) => typeof x !== 'undefined');
    },
  },
  mounted() {
    this.customStyling();
    this.overFlowScroll();
    this.resizeCols();
    const { path } = this.$route;
    this.selected = this.sort[path]?.[0] ?? [];
    this.desc = this.sort[path]?.[1] ?? [];
    // 梱包明細画面下枠の左上のチェックボックスを非表示する。
    if (path === '/konpomeisai') {
      document
        .querySelectorAll('thead tr:not(thead tr.v-data-table__progress)')
        .forEach((element) =>
          element.childNodes.forEach((child) =>
            child.innerText === this.labels.CARTON_NO ? (child.parentElement.id = 'table2') : void 0,
          ),
        );
      document.querySelector(' thead tr#table2 div.v-data-table__checkbox.v-simple-checkbox').classList.add('d-none');
    }
  },
  updated() {
    this.fixCols();
  },
  methods: {
    ...mapActions({
      setSort: 'ui/setSort',
    }),
    resizeCols() {
      const tables = document.getElementsByTagName('table');
      for (let i = 0; i < tables.length; i++) {
        resizableGrid(tables[i], false);
      }
    },
    sendSelectedRows(rows) {
      this.$emit('row-selected', rows);
    },
    resetSelected() {
      this.itemSelected = [];
      this.$refs.table.toggleSelectAll();
    },
    sortColSelect(sortBy) {
      this.selected = sortBy;
      const { path } = this.$route;
      this.setSort({ path, sortBy });
    },

    getMaxTableHeight() {
      setImmediate(
        function () {
          // BaseTableが画面遷移してもDestroyされないためoverFlowScrollにid付けるとき
          // 正しいidが付けられるようresetする
          this.tables.length = 0;

          //
          this.tables = [...document.querySelectorAll('tbody')].map(({ clientHeight, parentNode }) => {
            return { clientHeight, id: parentNode?.parentNode?.id ?? '0' };
          });
          document
            .querySelectorAll('div.v-data-table__wrapper')
            .forEach((table) => this.adjustScrollBottomPosition(table));
        }.bind(this),
      );
    },
    customStyling() {
      document.querySelectorAll('tr div.v-input--selection-controls__input').forEach((element) => {
        element.classList.add('mr-0');
      });
    },
    /**
     * テーブルにonScroll Eventで高さを調整する。
     * overflowの時テーブルの行数が100->50などに変わる時
     * Scrollの高さが変わらないため、
     * 一番下の行位置に.scrollToでscrollし、そこから調整で-40px
     */

    overFlowScroll() {
      document.querySelectorAll('div.v-data-table__wrapper').forEach((table, index) => {
        table.id = index;
        table.style.maxHeight = this.$route.path !== '/konpomeisai' ? '75vh' : '50vh';
        table.style.overflow = 'scroll';
        table.onscroll = function () {
          this.adjustScrollBottomPosition(table);
        }.bind(this);
      });
    },

    /**
     * 最後の行の位置にScroll
     * @param {object} table
     */

    adjustScrollBottomPosition(table) {
      const { scrollTop, scrollLeft, clientHeight } = table;
      /**
       * Scrollの高さから40px調整
       * @type number
       */
      const deltaHeight = clientHeight - 40;
      this.tables.forEach(({ clientHeight, id }) => {
        if (table.id === id && scrollTop > clientHeight - deltaHeight) {
          table.scrollTo(scrollLeft, clientHeight - deltaHeight);
        }
      });
    },
    /**
     * ソート可能なヘッダーを押すと固定にされている列が固定のままにしてもらう処理
     */
    fixColsBySorting(sortDesc) {
      const { path } = this.$route;
      this.setSort({ path, sortDesc });
      setImmediate(
        function () {
          this.fixCols();
        }.bind(this),
      );
    },
    /**
     * 固定に設定しているテーブルの列を画面が表示された時（mounted）もしくはテーブルが何かしら更新された時
     * 列を固定のままにしておく処理
     */
    fixCols() {
      const cols = [...document.querySelectorAll('.stick-to-the-left')];
      const headers = [...document.querySelectorAll('.header-stick-to-the-left')];
      cols.forEach((col, index) => {
        let i = index < this.fixedColWidths.length ? index : index % this.fixedColWidths.length;
        col.style.left = this.fixedColWidths[i] + 'px';
      });
      headers.forEach((header, index) => {
        header.style.left = this.fixedColWidths[index] + 'px';
      });
    },
    customSort(items, keys, isDesc) {
      const pickingCompFlagKey = 'picking_complete_flag';
      for (let i = keys.length - 1; i >= 0; i--) {
        if (keys[i] === pickingCompFlagKey) {
          items.sort((a, b) => {
            if (isDesc[i]) {
              return a[pickingCompFlagKey] > b[pickingCompFlagKey] ? 1 : -1;
            } else {
              return a[pickingCompFlagKey] >= b[pickingCompFlagKey] ? -1 : 1;
            }
          });
        } else {
          items.sort((a, b) => {
            let current = a[keys[i]];
            let next = b[keys[i]];
            current = current === null || current === '' ? '0' : current;
            next = next === null || next === '' ? '0' : next;
            if (isDesc[i]) {
              return current > next ? -1 : 1;
            } else {
              return current >= next ? 1 : -1;
            }
          });
        }
      }
      return items;
    },
  },
};
</script>
<style scoped>
.--min-cell-width {
  min-width: 50px;
}
</style>
<style scoped>
.theme--light.v-data-table :deep() td.stick-to-the-left {
  position: sticky;
  left: 0px;
  z-index: 2;
  background: inherit;
}

.theme--dark.v-data-table :deep() td.stick-to-the-left {
  position: sticky;
  left: 0px;
  z-index: 2;
  background: #1e1e1e;
  /* background: inherit; */
}

.theme--light.v-data-table :deep() th.header-stick-to-the-left {
  position: sticky;
  left: 0px;
  z-index: 3;
  background: rgb(224, 224, 224);
}

.theme--dark.v-data-table :deep() th.header-stick-to-the-left {
  position: sticky;
  left: 0px;
  z-index: 3;
  background: rgba(0, 43, 91, 1);
}

.theme--light.v-data-table :deep() td.stick-to-the-right {
  position: sticky;
  right: 0px;
  z-index: 2;
  background: inherit;
}

.theme--dark.v-data-table :deep() td.stick-to-the-right {
  position: sticky;
  right: 0px;
  z-index: 2;
  background: #1e1e1e;
  /* background: inherit; */
}

.theme--light.v-data-table :deep() th.header-stick-to-the-right {
  position: sticky;
  right: 0px;
  z-index: 5;
  background: rgb(224, 224, 224);
}

.theme--dark.v-data-table :deep() th.header-stick-to-the-right {
  position: sticky;
  right: 0px;
  z-index: 5;
  background: rgba(0, 43, 91, 1);
}

.v-data-table :deep() table {
  min-width: var(--table-width);
}

.theme--light.v-data-table :deep() table {
  border: thin solid rgba(0, 0, 0, 0.9);
  border-radius: 4px;
}

.theme--dark.v-data-table :deep() table {
  border: thin solid rgba(255, 255, 255, 0.9);
  border-radius: 4px;
}

.theme--light.v-data-table--fixed-header :deep() thead th {
  background: rgb(224, 224, 224) !important;
  white-space: pre;
  top: 0;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 12%);
  /* position: sticky;
  z-index: 3; */
}

.theme--dark.v-data-table--fixed-header :deep() thead th {
  background: rgba(0, 43, 91, 1) !important;
  white-space: pre;
  top: 0;
  box-shadow: inset 0 -1px 0 rgb(255 255 255 / 12%);
  /* position: sticky;
  z-index: 3; */
}

.theme--light.v-data-table :deep() th:last-child,
.theme--light.v-data-table :deep() td:last-child,
.theme--dark.v-data-table :deep() th:last-child,
.theme--dark.v-data-table :deep() td:last-child {
  border-right: none !important;
}

.theme--light.v-data-table :deep() .v-data-footer,
.theme--dark.v-data-table :deep() .v-data-footer {
  border-top: none !important;
}

.theme--light.v-data-table :deep() th,
.theme--light.v-data-table :deep() td {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}

.theme--dark.v-data-table :deep() th,
.theme--dark.v-data-table :deep() td {
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.theme--light.v-data-table :deep() span.v-data-table-header__icon,
.theme--dark.v-data-table :deep() span.v-data-table-header__icon {
  opacity: 1;
}

.theme--light.v-data-table :deep() tbody tr#stop_hover:hover,
.theme--light.v-data-table :deep() tbody tr.text-decoration-line-through:hover {
  background: transparent !important;
}

.theme--light.v-data-table
  :deep()
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(#stop_hover):not(
    .text-decoration-line-through
  ):not(.not-change-hover-color) {
  background: #8acaff !important;
}

.theme--dark.v-data-table :deep() tbody tr#stop_hover:hover,
.theme--dark.v-data-table :deep() tbody tr.text-decoration-line-through:hover {
  background: transparent !important;
}

.theme--dark.v-data-table
  :deep()
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(#stop_hover):not(
    .text-decoration-line-through
  ):not(.not-change-hover-color) {
  background: #045ba2 !important;
}

.theme--light.v-data-table :deep() tr.v-data-table__selected:not(.disabled-row-color):not(.highlight-green) {
  background: #8acaff !important;
}

.theme--dark.v-data-table :deep() tr.v-data-table__selected:not(.disabled-row-color):not(.highlight-green) {
  background: #045ba2 !important;
}

.theme--light.v-data-table :deep() tr.highlight-green {
  background-color: #d3ffd3 !important;
}

.theme--dark.v-data-table :deep() tr.highlight-green {
  background-color: rgb(29, 120, 47) !important;
}

.theme--light.v-data-table :deep() tbody tr:nth-child(odd):not(.not-change-hover-color):not(.v-data-table__selected) {
  background: white !important;
}

.theme--light.v-data-table :deep() tbody tr:nth-child(even):not(.not-change-hover-color):not(.v-data-table__selected) {
  background: #ededed !important;
}

.theme--dark.v-data-table :deep() tbody tr:nth-child(odd):not(.not-change-hover-color):not(.v-data-table__selected) {
  background: initial !important;
}

.theme--dark.v-data-table :deep() tbody tr:nth-child(even):not(.not-change-hover-color):not(.v-data-table__selected) {
  background: #3a3a3abd !important;
}

/* テーブルチェックボックススタイル */
.theme--light.v-data-table :deep() thead th.text-start,
.theme--light.v-data-table :deep() tbody td.text-start,
.theme--dark.v-data-table :deep() thead th.text-start,
.theme--dark.v-data-table :deep() tbody td.text-start {
  padding: 0;
}

.theme--light.v-data-table :deep() tbody div.v-data-table__checkbox.v-simple-checkbox,
.theme--light.v-data-table :deep() tbody td.text-start div.v-simple-checkbox,
.theme--dark.v-data-table :deep() tbody div.v-data-table__checkbox.v-simple-checkbox,
.theme--dark.v-data-table :deep() tbody td.text-start div.v-simple-checkbox {
  text-align: center;
}

.theme--light.v-data-table :deep() tbody div.v-input--selection-controls__input,
.theme--dark.v-data-table :deep() tbody div.v-input--selection-controls__input {
  margin: 0;
}

/* ---テーブルチェックボックススタイル--- END */
</style>
