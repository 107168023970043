export default {
  setSuccess(store, successful) {
    store.commit('setSuccess', successful);
  },
  setLoading(store, status) {
    store.commit('setLoading', status);
  },
  setUploadLoadingStatus(store, status) {
    store.commit('setUploadLoadingStatus', status);
  },
  toggleShowModal(store) {
    store.commit('setShowModal');
  },
  setClickedConfirmFlag(store, isConfirmed) {
    store.commit('setClickedConfirm', isConfirmed);
  },
  setMode(store, mode) {
    store.commit('setMode', mode);
  },
  setModalTitle(store, title) {
    store.commit('setModalTitle', title);
  },
  setModalMessage(store, message) {
    store.commit('setModalMessage', message);
  },
  setModalSuccessBtnLabel(store, successBtnLabel) {
    store.commit('setModalSuccessBtnLabel', successBtnLabel);
  },
  setModalConfirmBtnLabel(store, confirmBtnLabel) {
    store.commit('setModalConfirmBtnLabel', confirmBtnLabel);
  },
  setModalCancelBtnLabel(store, cancelBtnLabel) {
    store.commit('setModalCancelBtnLabel', cancelBtnLabel);
  },
  resetModalContents(store) {
    store.commit('resetModalContents');
  },
  setCustomErrorMessage(store, message) {
    store.commit('setCustomErrorMessage', message);
  },
  setAccessDenied(store, httpCode) {
    store.commit('setAccessDenied', httpCode);
  },
  setShowInstruction(store, showInstruction) {
    store.commit('setShowInstruction', showInstruction);
  },
  setShowCourierRule(store, showCourierRule) {
    store.commit('setShowCourierRule', showCourierRule);
  },
  setSort(store, sortCols) {
    store.commit('setSort', sortCols);
  },
};
