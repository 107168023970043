export default {
  list(state) {
    return state.list;
  },
  editing(state) {
    return state.editing;
  },
  completionNotice(state) {
    return state.completionNotice;
  },
};
