import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';
// import { LATEST_AFTER_TOMORROW } from "../../../consts";

export default {
  namespaced: true,
  state() {
    return {
      // shukkayoteiDate: LATEST_AFTER_TOMORROW,
      shukkayoteiDate: null,
      latestAfterTomorrow: '',
      packingsReadyForShipment: [],
      shipTo: null,
      invoiceInfo: null,
      invoiceDetails: [],
      rate: 0,
      searchConditions: {},
    };
  },
  mutations,
  actions,
  getters,
};
