import Payload from '../../../components/customs/payload';
import moment from 'moment';
export default {
  setSeiyakuAnken(store, data) {
    const ankens = [];
    data.forEach((el) => {
      ankens.push({
        ...el,
        total_weight: el.total_weight === 0 ? '-' : el.total_weight,
        payment_date: el.payment_date ? moment(el.payment_date).format('YYYY/MM/DD') : null,
      });
    });
    store.commit('setSeiyakuAnken', ankens);
  },
  editSeiyakuAnken(store, rows) {
    store.commit(
      'setSeiyakuAnken',
      store.getters.seiyakuAnken.map((row) => {
        const element = rows.find((element) => element.packing_no === row.packing_no);
        if (element) {
          return Object.assign({}, row, element);
        } else {
          return row;
        }
      }),
    );
  },
  setCommentItemsActions({ commit }, payload) {
    commit('setCommentItemsMutations', payload);
  },
  editCommentItemsActions(store, rows) {
    store.commit('setCommentItemsMutations', store.getters.commentItems.concat(rows));
  },
  async registCommentActions({ dispatch }, payload) {
    const params = new Payload('regist-comment-packing-no', 'seiyakuAnken/editCommentItemsActions', '', payload).data;
    await dispatch('api/callAPI', params, { root: true });
  },
  async getDataSeiyakuAnkenCommentActions({ dispatch }, payload) {
    const params = new Payload(
      'common-endpoint',
      'seiyakuAnken/setCommentItemsActions',
      'getCommentsByPackingNo',
      payload,
    ).data;
    return await dispatch('api/callAPI', params, { root: true });
  },
  setSearchConditions(store, searchConditions) {
    store.commit('setSearchConditions', searchConditions);
  },
};
