export default {
  setProgress(state, progress) {
    state.progress = progress;
  },
  setSearchConditions(state, payload) {
    const { storeName, searchConditions } = payload;
    state.searchConditions[storeName] = searchConditions;
  },
  resetSearchConditions(state) {
    state.searchConditions = {
      seiyakuAnken: {
        invoiceDate: [null, null],
        progress: null,
      },
      shukkaAnkenKakutei: {
        progress: null,
      },
      shukkaShoruiSakusei: {
        shukkayoteiDate: null,
        progress: null,
      },
    };
  },
  setSearchConditionsInitialValues(state) {
    state.searchConditions = {
      seiyakuAnken: {
        invoiceDate: state.seiyakuAnkenDateRange,
        progress: null,
      },
      shukkaAnkenKakutei: {
        shukkayoteiDate: [],
        progress: null,
      },
      shukkaShoruiSakusei: {
        shukkayoteiDate: state.shukkayoteiDate,
        progress: null,
      },
      shukkaJunbiHikiWatashi: {
        shukkayoteiDate: null,
        progress: null,
      },
    };
  },
};
