export default {
  dashboardItems(state) {
    return state.dashboardItems;
  },
  konpomeisai(state) {
    return state.konpomeisai;
  },
  seiyakuAnken(state) {
    return state.seiyakuAnken;
  },
  invoiceLists(state) {
    return state.invoiceLists;
  },
  packingDetails(state) {
    return state.packingDetails;
  },
  invoiceDetails(state) {
    return state.invoiceDetails;
  },
  boxTypes(state) {
    return state.boxTypes;
  },
};
