export default {
  regions: (state) => {
    return state.regions;
  },
  dashboardItems: (state) => {
    return state.dashboardItems;
  },
  checkAll: (state) => {
    return state.checkAll;
  },
  reactiveVar: (state) => state.reactiveVar,
  csvFlag: (state) => state.csvFlag,
};
