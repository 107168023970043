import Payload from '@/components/customs/payload';
import router from '@/router/index';
import axios from 'axios';
import Cookies from 'vue-cookies';

const baseUrl = process.env.VUE_APP_API_URL;
const getSummaryNextAction = 'summary/set';
const message = 'クーリエAPIに送信成功';
const doSuccessProcess = (store) => {
  store.commit('ui/setSuccess', true, { root: true });
  store.commit('ui/setShowModal', true, { root: true });
  store.commit('ui/setModalTitle', '成功', { root: true });
  store.commit('ui/setModalMessage', message, { root: true });
  store.commit('ui/setModalSuccessBtnLabel', '閉じる', { root: true });
};
export default {
  async createShipmentFedex(store, body = {}) {
    const apiEndPoint = 'create-shipment-fedex';
    const response = await store.dispatch('callAPI', {
      apiEndPoint,
      body,
    });
    if (!response.error) doSuccessProcess(store);
  },
  async retreiveShipmentFedex(store, body = {}) {
    const apiEndPoint = 'retreive-shipment-fedex';
    const response = await store.dispatch('callAPI', {
      apiEndPoint,
      body,
    });
    if (!response.error) doSuccessProcess(store);
  },
  async cancelShipmentFedex(store, body = {}) {
    const apiEndPoint = 'cancel-shipment-fedex';
    await store.dispatch('callAPI', { apiEndPoint, body });
  },
  async createShipmentDHL(store, body = {}) {
    const apiEndPoint = 'dhl-shipment-create';
    const response = await store.dispatch('callAPI', {
      apiEndPoint,
      body,
    });
    if (!response.error) doSuccessProcess(store);
  },
  async cancelShipmentDHL(store, body = {}) {
    const apiEndPoint = 'dhl-shipment-cancel';
    await store.dispatch('callAPI', { apiEndPoint, body });
  },
  async createShipmentUPS(store, body = {}) {
    const apiEndPoint = 'ups-shipments-create';
    const response = await store.dispatch('callAPI', {
      apiEndPoint,
      body,
    });
    if (!response.error) doSuccessProcess(store);
  },
  async cancelShipmentUPS(store, body = {}) {
    const apiEndPoint = 'ups-shipments-cancel';
    await store.dispatch('callAPI', { apiEndPoint, body });
  },
  /**
   * - [Lambda](../../../../../infrastructure/common/shukka-system/gateway-resource/common-endpoint/source/index.js)
   * - [Stored Function](../../../../../ddl/create/function/f_update_packing_info_courier_hard_cancel.sql)
   */
  async hardCancelShipment(store, apiParams) {
    const params = new Payload('common-endpoint', '', 'updatePackingInfoCourierHardCancel', apiParams).data;
    await store.dispatch('callAPI', params);
  },
  async getSummary(store) {
    const apiEndPoint = 'get-summary';
    const vuexAction = getSummaryNextAction;
    await store.dispatch('callAPI', { apiEndPoint, vuexAction });
  },
  async listBoxType(store) {
    const apiEndPoint = 'list-box-type';
    const vuexAction = 'boxTypeMaster/setBoxTypes';
    await store.dispatch('callAPI', { apiEndPoint, vuexAction });
  },
  async updateBoxType(store, index) {
    const body = store.rootState.boxTypeMaster.boxTypes[index];
    const apiEndPoint = 'update-box-type';
    const vuexAction = 'api/listBoxType';
    await store.dispatch('callAPI', { apiEndPoint, body, vuexAction });
  },
  async listCourier(store) {
    const apiEndPoint = 'list-courier-limit';
    const vuexAction = 'courierLimitMaster/setList';
    await store.dispatch('callAPI', { apiEndPoint, vuexAction });
  },
  async notifyAndRefreshCourier(store) {
    store.commit('courierLimitMaster/notifyCompletion', undefined, {
      root: true,
    });
    const apiEndPoint = 'list-courier-limit';
    const vuexAction = 'courierLimitMaster/setList';
    await store.dispatch('callAPI', { apiEndPoint, vuexAction });
  },
  async putCourierLimit(store, item) {
    const isArray = Array.isArray(item);
    const body = { registCouriers: isArray ? item : [item] };
    const apiEndPoint = 'put-courier-limit';
    const vuexAction = isArray ? 'api/notifyAndRefreshCourier' : 'api/listCourier';
    await store.dispatch('callAPI', { apiEndPoint, body, vuexAction });
  },
  async getCourierAccountInfo(store, courier) {
    const params = new Payload('get-courier-info', 'courier/setCourierAccountInfo', '', courier).data;
    await store.dispatch('callAPI', params);
  },
  async getSeiyakuAnken(store, formData) {
    const params = new Payload('search-packing-list', 'seiyakuAnken/setSeiyakuAnken', '', formData).data;
    store.dispatch(
      'common/setSearchConditions',
      {
        searchConditions: formData,
        storeName: 'seiyakuAnken',
      },
      { root: true },
    );
    await store.dispatch('callAPI', params);
  },

  async getShipmentConfirmPackings(store, formData) {
    const params = new Payload('search-confirm-packings', 'shukkaAnkenKakutei/setShipmentConfirmPackings', '', formData)
      .data;
    store.dispatch(
      'common/setSearchConditions',
      {
        searchConditions: formData,
        storeName: 'shukkaAnkenKakutei',
      },
      { root: true },
    );
    await store.dispatch('callAPI', params);
  },

  async updatePackingInfo(store, apiParams) {
    const params = new Payload('update-packing-info', 'seiyakuAnken/editSeiyakuAnken', '', apiParams).data;
    await store.dispatch('callAPI', params);
    store.dispatch('getSummary');
  },

  async updateShipmentConfirmPackings(store, apiParams) {
    const params = new Payload('update-packing-info', 'shukkaAnkenKakutei/editShipmentConfirmPacking', '', apiParams)
      .data;
    await store.dispatch('callAPI', params);
  },

  async downloadFile(store, apiParams) {
    const params = new Payload('download-file', '', '', apiParams).data;
    const url = await store.dispatch('callAPI', params);
    return (location.href = url);
  },

  async downloadPackingExcels(store, apiParams) {
    const params = new Payload('download-packing-excels', '', '', apiParams).data;
    const url = await store.dispatch('callAPI', params);
    return (location.href = url);
  },

  async downloadMergedExcels(store, apiParams) {
    const params = {
      method: 'post',
      apiEndPoint: 'download-merged-excels',
      body: apiParams,
      vuexAction: '',
    };
    const url = await store.dispatch('callAPI', params);
    return (location.href = url);
  },

  async downloadPrintExcel(store, apiParams) {
    const params = new Payload('download-print-excel', '', '', apiParams).data;
    const url = await store.dispatch('callAPI', params);
    return (location.href = url);
  },

  async downloadMergedPdf(store, apiParams) {
    const params = new Payload('download-merged-pdf', '', '', apiParams).data;
    return await store.dispatch('callAPI', params);
  },

  async downloadRemakeCSV(store, apiParams) {
    const params = new Payload('export-invoice-remake-csv', '', '', apiParams).data;
    const url = await store.dispatch('callAPI', params);
    return (location.href = url);
  },

  async downloadResultCSV(store, apiParams) {
    const params = new Payload('export-search-result-csv', '', '', apiParams).data;
    const url = await store.dispatch('callAPI', params);
    return (location.href = url);
  },
  async downloadPreparedShipmentListCSV(store, apiParams) {
    const params = new Payload('export-prepared-ship-list', '', '', apiParams).data;
    const url = await store.dispatch('callAPI', params);
    return (location.href = url);
  },

  async updatePackingPaymentDate(store, apiParams) {
    const params = new Payload('update-payment-date', '', '', apiParams).data;
    return await store.dispatch('callAPI', params);
  },

  async registerInvoiceCsv(store, apiParams) {
    const params = new Payload('register-invoice-csv', '', '', apiParams).data;
    return await store.dispatch('callAPI', params);
  },

  async deletePriority(store, apiParams) {
    const params = new Payload('delete-priority', '', 'deletePriority', apiParams).data;
    return await store.dispatch('callAPI', params);
  },

  async updatePackingStatus(store, apiParams) {
    const params = new Payload('update-picking-complete-flag', '', '', apiParams).data;
    return await store.dispatch('callAPI', params);
  },

  async getAwsCredentials(store, apiParams) {
    const params = new Payload('get-aws-credentials', '', '', apiParams).data;
    return await store.dispatch('callAPI', params);
  },

  async updateScheduledShippingDate(store, apiParams) {
    const params = new Payload('update-shipping-date', 'shukkaAnkenKakutei/editShipmentConfirmPacking', '', apiParams)
      .data;
    return await store.dispatch('callAPI', params);
  },

  // 箱タイプリスト取得
  async getBoxTypes(store) {
    const params = new Payload('common-endpoint', 'konpomeisai/setBoxTypes', 'getBoxTypes').data;
    await store.dispatch('callAPI', params);
  },

  // GBS→SIPS連携時失敗したインヴォイスリスト取得
  async getFailedInvoiceLists(store) {
    const params = new Payload('common-endpoint', 'konpomeisai/setInvoiceLists', 'getFailedInvoices').data;
    await store.dispatch('callAPI', params);
  },

  // インヴォイスリスト取得
  async getInvoiceLists(store, hakomatomezumi) {
    const params = new Payload(
      'common-endpoint',
      'konpomeisai/setInvoiceLists',
      'getInvoiceListPacking',
      hakomatomezumi ? ['zumi'] : [null],
    ).data;
    await store.dispatch('callAPI', params);
  },

  // インヴォイス詳細リスト取得
  async getInvoiceDetails(store, invoiceNo) {
    const params = new Payload('common-endpoint', 'konpomeisai/setInvoiceDetails', 'getInvoiceDetails', invoiceNo).data;
    await store.dispatch('callAPI', params);
  },
  // インヴォイス詳細リスト取得
  async getInvoiceDetailsForPreviewModal(store, invoiceNo) {
    const params = new Payload(
      'common-endpoint',
      'shukkaShoruiSakusei/setInvoiceDetails',
      'getInvoiceDetailsCreatingShippingDoc',
      [invoiceNo],
    ).data;
    await store.dispatch('callAPI', params);
  },

  // 梱包明細テーブル②に移したパッキング（BOX)取得
  async getPackingDetails(store, invoiceNo) {
    const params = new Payload('common-endpoint', 'konpomeisai/setPackingDetails', 'getPackingDetails', invoiceNo).data;
    await store.dispatch('callAPI', params);
  },

  // 自動まとめ処理
  async autoConsolidateBoxes(store, selectedInvoice) {
    if (selectedInvoice) {
      const params = new Payload('auto-consolidate', 'konpomeisai/setPackingDetails', '', selectedInvoice).data;
      params.body = selectedInvoice;
      return await store.dispatch('callAPI', params);
    }
    return [];
  },

  // 案件ごとキャンセル
  async cancelAnken(store, invoiceId) {
    if (invoiceId) {
      const params = new Payload('common-endpoint', '', 'deleteMatter', [
        invoiceId,
        store.rootGetters['defined/user'].userName,
      ]).data;
      return await store.dispatch('callAPI', params);
    }
  },

  // 案件ごとキャンセル取消
  async undoCancelAnken(store, invoiceId) {
    if (invoiceId) {
      const params = new Payload('common-endpoint', '', 'undoDeleteMatter', [
        invoiceId,
        store.rootGetters['defined/user'].userName,
      ]).data;
      return await store.dispatch('callAPI', params);
    }
  },

  // BOXキャンセル
  async cancelBox(store, payload = { invoiceId: null, boxNos: [] }) {
    if (payload.invoiceId && payload.boxNos.length > 0) {
      const params = new Payload('common-endpoint', '', 'cancelBox', [
        payload.invoiceId,
        payload.boxNos,
        store.rootGetters['defined/user'].userName,
      ]).data;
      return await store.dispatch('callAPI', params);
    }
  },

  // BOXキャンセル取消
  async undoCancelBox(store, payload = { invoiceId: null, boxNos: [] }) {
    if (payload.invoiceId && payload.boxNos.length > 0) {
      const params = new Payload('common-endpoint', '', 'cancelBoxUndo', [
        payload.invoiceId,
        payload.boxNos,
        store.rootGetters['defined/user'].userName,
      ]).data;
      return await store.dispatch('callAPI', params);
    }
  },

  // 梱包明細テーブル②保存処理
  async uploadPackingDetails(store, payload) {
    const params = new Payload('update-packing', '', '', payload).data;
    await store.dispatch('callAPI', params);
  },

  async getPackingStatusList(store) {
    const params = new Payload('common-endpoint', 'defined/setPackingStatusList', 'getPackingStatusNameList', null)
      .data;
    await store.dispatch('callAPI', params);
  },

  //権限取得
  async getAuthority(store) {
    const params = new Payload('get-authority', 'defined/setUserKbnAction', '', null).data;
    await store.dispatch('callAPI', params);
  },

  // 出荷書類作成に移れるパッキングリスト取得
  async getPackingsReadyForShipment(store, data) {
    const apiEndPoint = 'get-packings-for-shipment';
    const body = [
      data.shukkayoteiDate ?? null,
      !data.progress ? data.shukkayoteiDate : null,
      data.shukkatoshiNo ?? null,
      data.ankenNo ?? null,
      data.buyerName ?? null,
      data.status ?? null,
      data.progress ?? null,
      data.courier ?? null,
      data.shutanto ?? null,
    ].map((arg) => (arg !== '' ? arg : null));
    const vuexAction = 'shukkaShoruiSakusei/setPackingsReadyForShipment';
    store.dispatch(
      'common/setSearchConditions',
      {
        searchConditions: data,
        storeName: 'shukkaShoruiSakusei',
      },
      { root: true },
    );
    await store.dispatch('callAPI', { apiEndPoint, body, vuexAction });
  },

  // 出荷書類画面編集
  async updateEachPackingInfo(store, formData) {
    const params = new Payload(
      'common-endpoint',
      'shukkaShoruiSakusei/editPackingsReadyForShipment',
      'updatePackingInfoCreatingShipmentDoc',
      formData,
    ).data;

    await store.dispatch('callAPI', params);
  },

  // 予約番号一括入力
  async addReservationNumber(store, formData) {
    const params = new Payload('common-endpoint', '', 'updatePackingReservationNumber', formData).data;
    await store.dispatch('callAPI', params);
  },

  // 出荷指示解除処理
  async revertShipment(store, shippingSeqNumbers) {
    if (shippingSeqNumbers.length === 0) {
      return;
    }
    const params = new Payload('common-endpoint', '', 'revertShipment', [shippingSeqNumbers]).data;
    await store.dispatch('callAPI', params);
  },

  // 出荷書類画面 レビュー インボイス情報取得
  async getInvoiceInfo(store, invoiceNo) {
    if (!invoiceNo) {
      return;
    }
    const params = new Payload(
      'common-endpoint',
      'shukkaShoruiSakusei/setInvoiceInfo',
      'getInvoiceForCreatingShippingDoc',
      invoiceNo,
    ).data;
    await store.dispatch('callAPI', params);
  },

  // 送付先取得
  async getShipTo(store, query) {
    const params = new Payload('common-endpoint', 'shukkaShoruiSakusei/setShipTo', 'getShipTo', query).data;
    await store.dispatch('callAPI', params);
  },

  // 送付先取得 一時変更
  async getShipToTempChange(store, query) {
    const params = new Payload('common-endpoint', 'shukkaShoruiSakusei/setShipTo', 'getShipToTempChange', query).data;
    await store.dispatch('callAPI', params);
  },

  // getExchangeRate for insurance calculation
  async getExchangeRate(store) {
    const params = new Payload('common-endpoint', 'shukkaShoruiSakusei/setExchangeRate', 'getExchangeRate', '').data;
    await store.dispatch('callAPI', params);
  },

  // 送付先変更
  async updateShipTo(store, query) {
    const params = new Payload('common-endpoint', '', 'updatePackingInfoShipTo', query).data;
    await store.dispatch('callAPI', params);
  },

  // 送付先変更 手動入力
  async updateShipToManualInputs(store, manualInputs) {
    const params = new Payload('common-endpoint', '', 'updatePackingInfoShipToManualInputs', manualInputs).data;
    await store.dispatch('callAPI', params);
  },
  // クーリエレスポンス保存
  async saveCourierResponseInPacking(store, courierResponse) {
    const params = new Payload('common-endpoint', '', 'updatePackingInfoCourierResponse', courierResponse).data;
    await store.dispatch('callAPI', params);
  },

  // 定数マスタ登録
  async registerConstant(store, constant) {
    const params = new Payload('register-constant', '', 'insertConstant', constant).data;
    await store.dispatch('callAPI', params);
  },

  // 定数マスタ削除
  async deleteConstant(store, constant) {
    const params = new Payload('delete-constant', '', 'deleteConstant', constant).data;
    await store.dispatch('callAPI', params);
  },

  // 定数マスタ更新
  async updateConstant(store, constant) {
    const params = new Payload('update-constant', '', 'updateConstant', constant).data;
    await store.dispatch('callAPI', params);
  },

  // 定数マスタ一覧取得
  async getConstantList(store, constant = null) {
    const params = new Payload('common-endpoint', 'constantMaster/setConstantList', 'getConstantList', [constant]).data;
    await store.dispatch('callAPI', params);
  },

  // 定数マスタ一覧取得
  async getConstantListData(store, constant = null) {
    const params = new Payload('common-endpoint', '', 'getConstantList', [constant]).data;
    await store.dispatch('callAPI', params);
  },

  async callAPI(store, { apiEndPoint, body, vuexAction = '', method = 'post' }) {
    vuexAction !== getSummaryNextAction ? store.dispatch('ui/setLoading', true, { root: true }) : void 0;
    const headers = await store.dispatch('setHeader');
    const url = baseUrl + apiEndPoint;
    // console.log({ url });
    // console.log({ body });
    const request = method === 'post' ? axios.post(url, body, headers) : axios.get(url, headers);
    const { data } = await request.catch((error) => {
      const code503 = /status code 503/.test(String(error));
      const code401 = /status code 401/.test(String(error));
      if (code503) {
        store.dispatch('ui/setAccessDenied', 503, { root: true });
        router.push('/accessdenied');
      }
      if (code401) {
        store.dispatch('ui/setAccessDenied', 401, { root: true });
        router.push('/accessdenied');
        window.setTimeout(() => {
          window.location.href = process.env.VUE_APP_LOGIN_URL;
        }, 5000);
      }
      // エラーメッセージ表示
      store.dispatch('ui/setLoading', false, { root: true });
      store.dispatch('ui/setUploadLoadingStatus', false, { root: true });
      store.commit('ui/setErrorMessage', error, { root: true });
      throw error;
    });
    if (vuexAction) store.dispatch(vuexAction, data, { root: true });
    vuexAction !== getSummaryNextAction ? store.dispatch('ui/setLoading', false, { root: true }) : void 0;
    return data;
  },

  setHeader(store) {
    const localStoreLabels = store.rootGetters['defined/localStoreLabels'];
    const headers = {
      headers: {
        Authorization: Cookies.get(localStoreLabels.SESSION_TOKEN),
        'Content-Type': 'application/json',
      },
    };
    return headers;
  },
};
