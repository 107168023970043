// import base from "../../../common/base";
export default {
  setShukkaHikiWatashiItemsMutations(state, payload) {
    const shukkaHikiWatashiItems = [];
    if (payload) {
      payload.map((item) => {
        shukkaHikiWatashiItems.push(item);
      });
    }
    state.shukkaHikiWatashiItems = shukkaHikiWatashiItems;
  },
  setCommentItemsMutations(state, payload) {
    const commentItems = [];
    if (payload) {
      payload.map((item) => {
        commentItems.push(item);
      });
    }
    state.commentItems = commentItems;
  },
};
