import Payload from '../../../components/customs/payload';
export default {
  setPriorityShipActions({ commit }, payload) {
    commit('setPriorityShipMutations', payload);
  },
  setIssueDateWeekPulldownActions({ commit }, payload) {
    commit('setIssueDateWeekPulldownMutations', payload);
  },
  updatePriorityShipDataActions(store, rows) {
    store.commit(
      'updatePriorityShipMutations',
      store.getters.priorityShipItems.map((row) => {
        const element = rows.find(
          (element) =>
            String(element.issue_date) === String(row.issue_date) &&
            String(element.member_id) === String(row.member_id),
        );
        if (element) {
          return Object.assign({}, row, element);
        } else {
          return row;
        }
      }),
    );
  },
  async getIssueDateWeekPulldownActions({ dispatch }) {
    const params = new Payload(
      'common-endpoint',
      'priorityShip/setIssueDateWeekPulldownActions',
      'getIssueDateWeekPulldown',
      [],
    ).data;
    await dispatch('api/callAPI', params, { root: true });
  },

  async registPriorityShipActions(store, payload) {
    const date = payload.split(' ~ ');
    const priorityShipItems = [];
    let index = 0;
    store.getters.priorityShipItems.map((item) => {
      index++,
        priorityShipItems.push({
          issue_date: item.issue_date,
          member_id: item.member_id,
          matter_no_csv: item.matter_no_csv,
          // total_quantity: item.total_quantity,
          // total_weight: item.total_weight,
          priority: store.getters.csvFlag ? item.priority : index,
          status: 0, //登録
          index: index,
        });
    });
    const params = new Payload('regist-priority-ship', 'priorityShip/updatePriorityShipDataActions', '', {
      fromDate: date[0],
      toDate: date[1],
      priorityShipItems: priorityShipItems,
      priorityRange: priorityShipItems.length,
    }).data;
    await store.dispatch('api/callAPI', params, {
      root: true,
    });
  },

  async getDataPriorityShipActions({ dispatch }, payload) {
    if (payload) {
      const req = payload.split(' ~ ');
      const params = new Payload('common-endpoint', 'priorityShip/setPriorityShipActions', 'getPriorityList', [
        req[0],
        req[1],
      ]).data;
      await dispatch('api/callAPI', params, { root: true });
    }
  },

  async downloadPriorityShipFileActions({ dispatch }, payload) {
    if (payload) {
      const date = payload.split(' ~ ');
      const params = new Payload('export-priority-ship-file', '', '', {
        fromDate: date[0],
        toDate: date[1],
      }).data;
      const req = await dispatch('api/callAPI', params, { root: true });
      window.location.href = req.url;
    }
  },
};
