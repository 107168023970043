String.prototype.format = function () {
  let a = this;
  for (const k in arguments) {
    a = a.replace('{' + k + '}', arguments[k]);
  }
  return a;
};

String.prototype.commaString = function (suffix = '') {
  return this.toLocaleString() + suffix;
};

Number.prototype.commaString = function (suffix = '') {
  return this.toLocaleString() + suffix;
};
