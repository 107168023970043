const createDashboardItem = (label, key, regions, details) => {
  const values = regions.map((r) => ({ region: r.region, value: r[key] }));
  const sum = values.map((v) => v.value).reduce((a, b) => a + b, 0);
  return { label, key, values, sum, details };
};

export default {
  setDashboardItems(state, regions) {
    const items = [
      // 梱包明細
      createDashboardItem('梱包明細', 'unpackingCount', regions, []),
      // 成約案件確認
      createDashboardItem('成約案件確認', 'beforeShipmentCount', regions, [
        createDashboardItem('作業中', 'paidOrUnpaidShippingCount', regions, []),
        createDashboardItem('未入金', 'unpaidCount', regions, []),
      ]),
      // 優先順位作成
      {
        label: '優先順位作成',
        key: '-',
        values: regions.map((r) => ({ region: r.region, value: r['-'] })),
        details: [],
      },
      // 出荷案件確定
      createDashboardItem('出荷案件確定', 'shipmentConfirmCount', regions, [
        createDashboardItem('出荷予定確定前（作業中）', 'beforeScheduledCount', regions, []),
        createDashboardItem('出荷予定確定後（作業済）', 'afterScheduledCount', regions, []),
        createDashboardItem('出荷保留', 'shipmentPendingCount', regions, []),
      ]),
      // 出荷書類作成
      createDashboardItem('出荷書類作成', 'creatingDocsOrPreparedShipmentCount', regions, [
        createDashboardItem('出荷書類作成中（作業中）', 'creatingDocsCount', regions, []),
        createDashboardItem('出荷書類作成済（作業済）', 'createdDocsCount', regions, []),
        createDashboardItem('送付先変更', 'packageDestinationChangedCount', regions, []),
      ]),
      // 出荷準備-引渡
      createDashboardItem('出荷準備-引渡', 'preparedShipmentCount', regions, []),
      // 出荷済み案件
      createDashboardItem('出荷済み案件', 'shippedCount', regions, []),
    ];

    state.dashboardItems = items;
    state.regions = regions.map((r) => ({ id: r.region, name: r.regionName }));
    state.csvFlag = state.dashboardItems.every(
      (item) => Boolean(!item.checked) && item.details.every((d) => Boolean(!d.checked)),
    );
    state.checkAll = state.dashboardItems
      .filter((i) => i.key != '-')
      .every((item) => Boolean(item.checked) && item.details.every((d) => Boolean(d.checked)));
  },
  expand(state, key) {
    state.dashboardItems = state.dashboardItems.map((item) =>
      item.key === key ? { ...item, expand: !item.expand } : item,
    );
  },
  clickCheck(state, row) {
    state.dashboardItems = state.dashboardItems.map((item) => {
      if (item.key === row.key) {
        return {
          ...item,
          checked: !row.checked,
          details: item.details.map((d) => ({ ...d, checked: !row.checked })),
        };
      } else {
        item.details = item.details.map((d) => (d.key === row.key ? { ...d, checked: !row.checked } : d));
        if (item.details.length) {
          item.checked = item.details.every((d) => Boolean(d.checked));
        }
        return item;
      }
    });
    const next = state.dashboardItems
      .filter((i) => i.key != '-')
      .every((item) => Boolean(item.checked) && item.details.every((d) => Boolean(d.checked)));
    state.csvFlag = state.dashboardItems
      .filter((i) => i.key != '-')
      .every((item) => Boolean(!item.checked) && item.details.every((d) => Boolean(!d.checked)));
    state.checkAll = next;
  },
  clickCheckAll(state) {
    // 本当は次の1行は不要だけどエディターに配列であることを分からせておくと補完が効いて書きやすくなるので
    const items = [...state.dashboardItems];
    const next = !items
      .filter((i) => i.key != '-')
      .every((item) => Boolean(item.checked) && item.details.every((d) => Boolean(d.checked)));
    state.dashboardItems = items.map((item) => ({
      ...item,
      checked: next,
      details: item.details.map((d) => ({ ...d, checked: next })),
    }));
    state.checkAll = next;
    state.csvFlag = state.dashboardItems.every(
      (item) => Boolean(!item.checked) && item.details.every((d) => Boolean(!d.checked)),
    );
  },
};
