export default {
  setShipmentConfirmPackings(store, data) {
    store.commit('setShipmentConfirmPackings', data);
    return [];
  },
  editShipmentConfirmPacking(store, rows) {
    store.commit(
      'setShipmentConfirmPackings',
      store.getters.shipmentConfirmPackings.map((row) => {
        const element = rows.find((element) => element.packing_no === row.packing_no);
        if (element) {
          return Object.assign(row, row, element);
        } else {
          return row;
        }
      }),
    );
  },
  setSearchConditions(store, searchConditions) {
    store.commit('setSearchConditions', searchConditions);
  },
};
