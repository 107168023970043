import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';
import moment from 'moment';

export default {
  namespaced: true,
  state() {
    const thisTue =
      moment().isoWeekday() === 2
        ? moment().add(0, 'days').format('YYYY-MM-DD').toString()
        : moment().isoWeekday(2).format('YYYY-MM-DD').toString();
    const nextMon =
      moment().isoWeekday() === 2
        ? moment().add(6, 'days').format('YYYY-MM-DD').toString()
        : moment().isoWeekday(8).format('YYYY-MM-DD').toString();
    const isFriday = moment().isoWeekday() === 5;
    const businessDate = !isFriday
      ? moment().add(1, 'days').format('YYYY/MM/DD')
      : moment().day(8).format('YYYY/MM/DD');
    const dateRange = [thisTue === '' ? null : thisTue, nextMon === '' ? null : nextMon];
    return {
      progress: null,
      searchConditions: {
        seiyakuAnken: {
          invoiceDate: dateRange,
          progress: null,
        },
        shukkaAnkenKakutei: {
          shukkayoteiDate: [],
          progress: null,
        },
        shukkaShoruiSakusei: {
          shukkayoteiDate: businessDate,
          progress: null,
        },
        shukkaJunbiHikiWatashi: {
          shukkayoteiDate: null,
          progress: null,
        },
      },
      seiyakuAnkenDateRange: dateRange,
      shukkayoteiDate: businessDate,
    };
  },
  mutations,
  actions,
  getters,
};
