export default {
  constantList(state) {
    return state.constantList;
  },
  progress(state) {
    return state.progress;
  },
  searchConditions(state) {
    return state.searchConditions;
  },
};
