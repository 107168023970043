import Payload from '../../../components/customs/payload';
export default {
  setShukkaZumiAnkenItemsActions({ commit }, payload) {
    commit('setShukkaZumiAnkenItemsMutations', payload);
  },
  setCommentItemsActions({ commit }, payload) {
    commit('setCommentItemsMutations', payload);
  },
  editShukkaZumiAnkenItemsActions(store, rows) {
    store.commit(
      'setShukkaZumiAnkenItemsMutations',
      store.getters.shukkaZumiAnkenItems
        .map((row) => {
          const element = rows.find((element) => element.shipping_seq_number === row.shipping_seq_number);
          if (element) {
            return Object.assign({}, row, element);
          } else {
            return row;
          }
        })
        .filter((row) => row.cancel_flag != 1),
    );
  },
  editCommentItemsActions(store, rows) {
    store.commit('setCommentItemsMutations', store.getters.commentItems.concat(rows));
  },
  async getDataShukkaZumiAnkenItemsActions({ dispatch }, payload) {
    const params = new Payload('search-shipped-list', 'shukkaZumiAnken/setShukkaZumiAnkenItemsActions', '', payload)
      .data;
    dispatch(
      'common/setSearchConditions',
      {
        searchConditions: payload,
        storeName: 'shukkaZumiAnken',
      },
      { root: true },
    );
    await dispatch('api/callAPI', params, { root: true });
  },
  async registCommentActions({ dispatch }, payload) {
    const params = new Payload('regist-comment', 'shukkaZumiAnken/editCommentItemsActions', '', payload).data;
    await dispatch('api/callAPI', params, { root: true });
  },
  async getDataShukkaZumiAnkenCommentActions({ dispatch }, payload) {
    const params = new Payload('common-endpoint', 'shukkaZumiAnken/setCommentItemsActions', 'getComments', payload)
      .data;
    return await dispatch('api/callAPI', params, { root: true });
  },
  async updateShukkaZumiAnkenItemsActions(store, apiParams) {
    const params = new Payload(
      'update-shipped-packing',
      'shukkaZumiAnken/editShukkaZumiAnkenItemsActions',
      '',
      apiParams,
    ).data;
    await store.dispatch('api/callAPI', params, {
      root: true,
    });
  },
};
