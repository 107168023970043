// let colWidth = 0;
function resizableGrid(table, overflowHidden = false) {
  var row = table.getElementsByTagName('tr')[0],
    cols = row ? row.children : undefined;
  if (!cols) return;

  table.style.overflow = overflowHidden ? 'auto' : 'auto';

  var tableHeight = table.offsetHeight;

  for (var i = 0; i < cols.length - 1; i++) {
    var div = createDiv(tableHeight);
    cols[i].appendChild(div);
    // cols[i].style.position = "sticky";
    setListeners(div, cols[i]);
  }
  // console.log({ colWidth });
}

function setListeners(div) {
  var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

  // バブリング抑止
  div.addEventListener('click', function (e) {
    e.stopPropagation();
  });

  div.addEventListener('mousedown', function (e) {
    curCol =
      e.target.parentElement.classList.contains('header-stick-to-the-left') ||
      e.target.parentElement.classList.contains('header-stick-to-the-right')
        ? undefined
        : e.target.parentElement;
    nxtCol = curCol ? curCol.nextElementSibling : undefined;
    pageX = e.pageX;

    var padding = curCol ? paddingDiff(curCol) : undefined;

    curColWidth = curCol ? curCol.offsetWidth - padding : undefined;
    if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
  });

  // div.addEventListener("mouseover", function (e) {
  //   e.target.style.borderRight = "2px solid #1976D2";
  // });

  div.addEventListener('mouseout', function (e) {
    e.target.style.borderRight = '';
  });

  document.addEventListener('mousemove', function (e) {
    if (curCol) {
      var diffX = e.pageX - pageX;

      if (nxtCol) nxtCol.style.minWidth = nxtColWidth - diffX + 'px';

      curCol.style.minWidth = curColWidth + diffX + 'px';
      // colWidth = curCol.style.minWidth;
    }
  });

  document.addEventListener('mouseup', function () {
    curCol = undefined;
    nxtCol = undefined;
    pageX = undefined;
    nxtColWidth = undefined;
    curColWidth = undefined;
  });
}

function createDiv(height) {
  var div = document.createElement('div');
  div.style.top = 0;
  div.style.right = 0;
  div.style.width = '5px';
  div.style.position = 'absolute';
  div.style.cursor = 'col-resize';
  div.style.userSelect = 'none';
  div.style.height = height + 'px';
  return div;
}

function paddingDiff(col) {
  if (getStyleVal(col, 'box-sizing') == 'border-box') {
    return 0;
  }

  var padLeft = getStyleVal(col, 'padding-left');
  var padRight = getStyleVal(col, 'padding-right');
  return parseInt(padLeft) + parseInt(padRight);
}

function getStyleVal(elm, css) {
  return window.getComputedStyle(elm, null).getPropertyValue(css);
}

export { resizableGrid };
