require('./common/prototype');

import Vue from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import './registerServiceWorker';
import router from './router';
import store from './store';

import BaseTable from './components/ui/BaseTable.vue';
const BaseForm = () => import(/* webpackChunkName: "BaseForm" */ './components/ui/BaseForm.vue');
const BaseExpansionPanel = () =>
  import(/* webpackChunkName: "BaseExpansionPanel" */ './components/ui/BaseExpansionPanel.vue');
const CsvResultDl = () => import(/* webpackChunkName: "CsvResultDl" */ './components/ui/CsvResultDlBtn.vue');

Vue.component('base-form', BaseForm);
Vue.component('base-table', BaseTable);
Vue.component('base-expansion-panel', BaseExpansionPanel);
Vue.component('csv-result-dl', CsvResultDl);
Vue.use(VueCookies);
VueCookies.config('1d');

// https://cli.vuejs.org/guide/mode-and-env.html#using-env-variables-in-client-side-code
if (process.env.NODE_ENV === 'development') {
  VueCookies.set('jwt', process.env.VUE_APP_JWT);
}

Vue.config.productionTip = false;
Vue.config.silent = true;

new Vue({
  router,
  store,
  vuetify,
  VueCookies,
  render: (h) => h(App),
}).$mount('#app');
