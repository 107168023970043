import Payload from '../../../components/customs/payload';

export default {
  setShukkaHikiWatashiItemsActions({ commit }, payload) {
    commit('setShukkaHikiWatashiItemsMutations', payload);
  },
  setCommentItemsActions({ commit }, payload) {
    commit('setCommentItemsMutations', payload);
  },
  editShukkaHikiWatashiItemsActions(store, rows) {
    store.commit(
      'setShukkaHikiWatashiItemsMutations',
      store.getters.shukkaHikiWatashiItems.map((row) => {
        const element = rows.find((element) => element.shipping_seq_number === row.shipping_seq_number);
        if (element) {
          return Object.assign({}, row, element);
        } else {
          return row;
        }
      }),
    );
  },
  editCommentItemsActions(store, rows) {
    store.commit('setCommentItemsMutations', store.getters.commentItems.concat(rows));
  },
  async getDataShukkaHikiWatashiItemsActions(store, formData) {
    const params = new Payload(
      'search-prepared-shipment',
      'shukkaJunbiHikiWatashi/setShukkaHikiWatashiItemsActions',
      '',
      formData,
    ).data;
    store.dispatch(
      'common/setSearchConditions',
      {
        searchConditions: formData,
        storeName: 'shukkaJunbiHikiWatashi',
      },
      { root: true },
    );
    await store.dispatch('api/callAPI', params, { root: true });
  },
  async getDataShukkaHikiWatashiCommentActions({ dispatch }, payload) {
    const params = new Payload(
      'common-endpoint',
      'shukkaJunbiHikiWatashi/setCommentItemsActions',
      'getComments',
      payload,
    ).data;
    return await dispatch('api/callAPI', params, { root: true });
  },
  async registCommentActions({ dispatch }, payload) {
    const params = new Payload('regist-comment', 'shukkaJunbiHikiWatashi/editCommentItemsActions', '', payload).data;
    await dispatch('api/callAPI', params, { root: true });
  },
  async updateShukkaHikiWatashiItemsActions(store, apiParams) {
    const params = new Payload(
      'update-prepared-shipment',
      'shukkaJunbiHikiWatashi/editShukkaHikiWatashiItemsActions',
      '',
      apiParams,
    ).data;
    await store.dispatch('api/callAPI', params, {
      root: true,
    });
  },
};
