export default {
  setList(state, payload) {
    state.list = payload;
    state.editing = null;
  },
  changeDraft(state, { key, value, pk }) {
    state.list = state.list.map((c) => {
      return `${c.courierId}-${c.countryCode}` === pk ? { ...c, [key]: Number(value) } : c;
    });
  },
  startEditing(state, item) {
    if (!state.editing) {
      state.editing = item;
    } else {
      const { editing } = state;
      state.list = state.list.map((c) => (`${c.courierId}-${c.countryCode}` === editing.pk ? editing : c));
      state.editing = item;
    }
  },
  cancelEditing(state) {
    const { editing } = state;
    state.list = state.list.map((c) => (`${c.courierId}-${c.countryCode}` === editing.pk ? editing : c));
    state.editing = null;
  },
  notifyCompletion(state) {
    state.completionNotice = true;
  },
  closeNotice(state) {
    state.completionNotice = false;
  },
};
