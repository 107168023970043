class Payload {
  constructor(apiEndPoint = '', vuexAction = '', ddlFunctionName = '', queryParams = '', method = 'post') {
    this.apiEndPoint = apiEndPoint;
    this.vuexAction = vuexAction;
    this.ddlFunctionName = ddlFunctionName;
    this.queryParams = queryParams;
    this.method = method;
  }
  get data() {
    return {
      method: this.method,
      apiEndPoint: this.apiEndPoint,
      body:
        this.queryParams === ''
          ? {
              func: this.ddlFunctionName,
            }
          : typeof this.queryParams === 'object'
            ? {
                func: this.ddlFunctionName,
                query: this.queryParams,
              }
            : {
                func: this.ddlFunctionName,
                query: [this.queryParams],
              },
      vuexAction: this.vuexAction,
    };
  }
}
module.exports = Payload;
