export default {
  setKonpomeisai(state, payload) {
    state.invoiceDetails = payload;
  },
  setBoxTypes(state, boxTypes) {
    state.boxTypes = boxTypes;
  },
  setInvoiceLists(state, invoiceLists) {
    state.invoiceLists = invoiceLists;
  },
  setInvoiceDetails(state, invoiceDetails) {
    state.invoiceDetails = invoiceDetails;
  },
  // setAutoConsolidatedBoxes(state, autoConsolidatedBoxes) {
  //   state.autoConsolidatedBoxes = autoConsolidatedBoxes;
  // },
  setPackingDetails(state, packingDetails) {
    state.packingDetails = packingDetails;
  },
  resetInvoiceDetails(state) {
    state.invoiceDetails = [];
  },
  resetPackingDetails(state) {
    state.packingDetails = [];
  },
};
