import base from '../../../common/base';
export default {
  setPriorityShipMutations(state, payload) {
    const priorityShipItems = [];
    if (payload) {
      payload.map((item) => {
        priorityShipItems.push(item);
      });
    }
    state.csvFlag = false;
    state.priorityShipItems = priorityShipItems;
  },
  updatePriorityShipMutations(state, payload) {
    state.csvFlag = false;
    state.priorityShipItems = state.priorityShipItems.map((item) => {
      const res = payload.find((i) => i.member_id === item.member_id && i.issue_date === item.issue_date);
      return Object.assign({}, item, res);
    });
  },
  clearPriorityShipDataMutations(state) {
    state.priorityShipItems = [];
    state.csvFlag = false;
  },
  setIssueDateWeekPulldownMutations(state, payload) {
    const issueDateWeekPulldownItem = [];
    if (payload) {
      payload.map((item) => {
        issueDateWeekPulldownItem.push({
          value: base.getFormatDate(item.from_date) + ' ~ ' + base.getFormatDate(item.to_date),
        });
      });
    }
    state.issueDateWeekPulldownItem = issueDateWeekPulldownItem;
  },
  priorityShipCsvImportMutations(state, payload) {
    const priorityShipItems = [];
    state.priorityShipItems = [];
    payload.map((item) => {
      priorityShipItems.push({
        issue_date: item[0],
        member_id: item[1],
        member_name_ja: item[2],
        matter_no_csv: item[3],
        total_quantity: item[4] ? item[4] : 0,
        total_weight: item[5] ? item[5] : 0,
        priority: item[6],
      });
    });
    state.csvFlag = true;
    state.priorityShipItems = priorityShipItems;
  },
};
