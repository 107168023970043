import Payload from '../../../components/customs/payload';
export default {
  setMinyukinAnkenItemsActions({ commit }, payload) {
    commit('setMinyukinAnkenItemsMutations', payload);
  },
  editMinyuukinAnkenItemsActions(store, rows) {
    store.commit(
      'setMinyukinAnkenItemsMutations',
      store.getters.minyukinAnkenItems.map((row) => {
        const element = rows.find((element) => element.packing_no === row.packing_no);
        if (element) {
          return Object.assign({}, row, element);
        } else {
          return row;
        }
      }),
    );
  },
  async getDataMinyukinAnkenItemsActions({ dispatch }, payload) {
    const params = new Payload('search-unpaid-packings', 'minyukinAnken/setMinyukinAnkenItemsActions', '', payload)
      .data;
    await dispatch('api/callAPI', params, { root: true });
  },
  async updateMinyuukinAnkenItemsActions(store, apiParams) {
    const params = new Payload('update-packing-info', 'minyukinAnken/editMinyuukinAnkenItemsActions', '', apiParams)
      .data;
    await store.dispatch('api/callAPI', params, {
      root: true,
    });
  },
};
