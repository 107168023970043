import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      minyukinAnkenItems: [],
      reactiveVar: {
        invoiceDate: [],
        ankenNo: '',
        buyerName: '',
        status: null,
      },
    };
  },
  mutations,
  actions,
  getters,
};
