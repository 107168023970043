export default {
  setSeiyakuAnken(state, payload) {
    state.seiyakuAnken = payload;
  },
  setCommentItemsMutations(state, payload) {
    const commentItems = [];
    if (payload) {
      payload.map((item) => {
        commentItems.push(item);
      });
    }
    state.commentItems = commentItems;
  },
  setSearchConditions(state, payload) {
    state.searchConditions = payload;
  },
};
