export default {
  setConstantList(store, constantList) {
    let constants = [];
    constantList.forEach((constant) => {
      constants.push({
        id: constant.id,
        keyString: constant.key_string,
        name: constant.value_name,
        value1: constant.value1,
        value2: constant.value2,
        value3: constant.value3,
        value4: constant.value4,
        value5: constant.value5,
        menteImpossibleFlag: constant.mente_impossible_flag,
        discription: constant.description,
        order: constant.sort_order,
      });
    });
    store.commit('setConstantList', constants);
  },
  resetConstantList(store) {
    store.commit('resetConstantList');
  },
};
