<template>
  <v-app>
    <Navbar v-if="httpCode !== 503 || httpCode !== 401" />
    <v-main v-if="httpCode !== 503 || httpCode !== 401">
      <v-container fluid>
        <v-slide-x-transition leave-absolute v-if="user.authority">
          <router-view />
        </v-slide-x-transition>
      </v-container>
    </v-main>
    <v-snackbar
      v-model="loading"
      content-class="my-class"
      color="rgba(0, 0, 0, 0)"
      tile
      elevation="0"
      centered
      transition="slide-y-transition"
      min-width="50"
      timeout="30000"
    >
      <v-progress-circular color="primary" indeterminate size="100" width="8"></v-progress-circular>
    </v-snackbar>
    <Modal />
    <div v-if="showInstruction">
      <instruction-manual-modal></instruction-manual-modal>
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'App',
  components: {
    Navbar: () => import(/* webpackChunkName: "Navbar" */ './components/ui/Navbar.vue'),
    Modal: () => import(/* webpackChunkName: "Modal" */ './components/ui/Modal.vue'),
    InstructionManualModal: () =>
      import(/* webpackChunkName: "InstructionManualModal" */ './components/ui/InstructionManualModal.vue'),
  },
  async created() {
    await this.$store.dispatch('api/getAuthority');
    await this.initialize();
  },
  mounted() {
    this.$cookies.get('dark') == 'true' ? (this.$vuetify.theme.dark = true) : void 0;
    const handlers = {
      '/seiyakuankenkakunin': this.apiGetSeiyakuAnken,
      '/shukkaankenkakutei': this.apiGetShipmentConfirmPackings,
      '/shukkashoruisakusei': this.apiGetPackingsReadyForShipment,
      '/shukkajunbihikiwatashi': this.apiGetDataShukkaHikiWatashiItemsActions,
    };
    setTimeout(
      function () {
        const { path, meta } = this.$route;
        handlers[path]?.(this.commonSearchConditions[meta.storeModule]);
      }.bind(this),
      500,
    );
  },
  computed: {
    ...mapGetters({
      localStoreLabels: 'defined/localStoreLabels',
      user: 'defined/user',
      loading: 'ui/loading',
      httpCode: 'ui/httpCode',
      showInstruction: 'ui/showInstruction',
      commonSearchConditions: 'common/searchConditions',
    }),
  },
  methods: {
    ...mapActions({
      // setShowInstruction: "ui/setShowInstruction",
      apiGetSeiyakuAnken: 'api/getSeiyakuAnken',
      apiGetShipmentConfirmPackings: 'api/getShipmentConfirmPackings',
      apiGetPackingsReadyForShipment: 'api/getPackingsReadyForShipment',
      apiGetExchangeRate: 'api/getExchangeRate',
      apiGetDataShukkaHikiWatashiItemsActions: 'shukkaJunbiHikiWatashi/getDataShukkaHikiWatashiItemsActions',
    }),
    async initialize() {
      await Promise.all([this.apiGetExchangeRate(), this.$store.dispatch('api/getPackingStatusList')]);
    },
  },
};
</script>
<style>
.v-snack__wrapper {
  margin-top: 0 !important;
}
td {
  white-space: pre-wrap;
}
td span {
  white-space: pre-wrap;
}
.v-btn__content {
  width: max-content;
}
</style>
