export default {
  success(state) {
    return state.success;
  },
  error(state) {
    return state.error;
  },
  loading(state) {
    return state.loading;
  },
  uploadLoading(state) {
    return state.uploadLoading;
  },
  showModal(state) {
    return state.showModal;
  },
  showCancelBtn(state) {
    return state.showCancelBtn;
  },
  clickedConfirm(state) {
    return state.clickedConfirm;
  },
  mode(state) {
    return state.mode;
  },
  modalTitle(state) {
    return state.modalTitle;
  },
  modalMessage(state) {
    return state.modalMessage;
  },
  modalSuccessBtnLabel(state) {
    return state.modalSuccessBtnLabel;
  },
  modalConfirmBtnLabel(state) {
    return state.modalConfirmBtnLabel;
  },
  modalCancelBtnLabel(state) {
    return state.modalCancelBtnLabel;
  },
  httpCode(state) {
    return state.httpCode;
  },
  showInstruction(state) {
    return state.showInstruction;
  },
  showCourierRule(state) {
    return state.showCourierRule;
  },
  sort({ sort }) {
    return sort;
  },
};
