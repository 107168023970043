import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';
import moment from 'moment';

export default {
  namespaced: true,
  state() {
    const thisTue =
      moment().isoWeekday() === 2
        ? moment().add(0, 'days').format('YYYY-MM-DD').toString()
        : moment().isoWeekday(2).format('YYYY-MM-DD').toString();
    const nextMon =
      moment().isoWeekday() === 2
        ? moment().add(6, 'days').format('YYYY-MM-DD').toString()
        : moment().isoWeekday(8).format('YYYY-MM-DD').toString();
    return {
      seiyakuAnken: [],
      commentItems: [],
      searchConditions: {
        invoiceDate: [thisTue, nextMon],
      },
    };
  },
  mutations,
  actions,
  getters,
};
