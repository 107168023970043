function getCourierCountriesStates(state, courier) {
  return state.constantList
    .filter((constant) => constant.keyString.includes(courier))
    .map(({ keyString, name, value1, value2 }) => ({
      country: courier.includes('_STATE') ? keyString.slice(-2) : name,
      name: name,
      code: value1,
      region: value2,
    }));
}
function getCourierDeliveryTypes(state, courier) {
  return state.constantList.filter(
    ({ keyString, value2 }) => keyString.includes('DELIVERY_SERVICE') && courier == value2,
  );
  // .map(({ name, value1, value3 }) => ({
  //   name: value1 == courier ? name : void 0,
  //   enum: value1 == courier ? value3 : void 0,
  // }));
}
export default {
  constantList(state) {
    return state.constantList;
  },
  otherPartsDescription(state) {
    const found = state.constantList?.find(({ value1 }) => value1 === 'other');
    return found?.discription ?? '';
  },
  courierCountriesStates(state) {
    return {
      1: {
        countryLists: getCourierCountriesStates(state, 'FEDEX_COUNTRY'),
        stateLists: getCourierCountriesStates(state, 'FEDEX_STATE'),
      },
      2: {
        countryLists: getCourierCountriesStates(state, 'UPS_COUNTRY'),
        stateLists: getCourierCountriesStates(state, 'UPS_STATE'),
      },
      3: {
        countryLists: getCourierCountriesStates(state, 'DHL_COUNTRY'),
        stateLists: getCourierCountriesStates(state, 'DHL_STATE'),
      },
    };
  },
  courierDeliveryTypes(state) {
    return {
      1: getCourierDeliveryTypes(state, 1),
      2: getCourierDeliveryTypes(state, 2),
      3: getCourierDeliveryTypes(state, 3),
    };
  },
  shippingTerms(state) {
    return state.constantList
      .map(({ keyString, name, value1, value2, value3, value4 }) =>
        keyString.includes('TRADE_TERMS')
          ? {
              name: name,
              code: Number(value1),
              fedex: value2,
              ups: value3,
              dhl: value4,
            }
          : '',
      )
      .filter((x) => x);
  },
  descriptionByCourier(state) {
    return state.constantList
      .map((d) => {
        return d.keyString === 'PACKAGE_DESCRIPTION' ? d : null;
      })
      .filter((x) => x);
  },
};
