import moment from 'moment';
import encoding from 'encoding-japanese';
export default {
  // Timestamp with time zoneをYYYY-MM-DDの形で返す
  getFormatDate(date, format = 'YYYY/MM/DD') {
    return date ? moment(date).format(format) : '';
  },

  // Timestamp with time zoneをHH-mmの形で返す
  getFormatTime(date) {
    return date ? moment(date).format('HH:mm') : '';
  },
  // Timestamp with time zoneをYYYY/MM/DD HH:mmの形で返す
  getFormatDateTime(date) {
    return date ? moment(date).format('YYYY/MM/DD HH:mm') : '';
  },
  // Csv読み込む
  parseCsv(file, callback) {
    try {
      let reader = new FileReader();
      reader.onload = function (e) {
        callback(e.target.result);
      };
      reader.readAsBinaryString(file);
    } catch (error) {
      callback(null);
    }
  },
  encodingConvert(x) {
    return encoding.convert(x, 'unicode', 'sjis').trim().replaceAll('"', '');
  },
};
