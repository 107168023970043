export default {
  setPackingsReadyForShipment(store, payload) {
    store.commit('setPackingsReadyForShipment', payload);
  },
  editPackingsReadyForShipment(store, rows) {
    store.commit(
      'setPackingsReadyForShipment',
      store.getters.packingsReadyForShipment.map((row) => {
        const element = rows.find((element) => element.shipping_seq_number === row.shipping_seq_number);
        if (element) {
          return Object.assign({}, row, element);
        } else {
          return row;
        }
      }),
    );
  },
  setShipTo(store, payload) {
    store.commit('setShipTo', payload);
  },
  reSetShipTo(store) {
    store.commit('setShipTo', null);
  },
  setInvoiceInfo(store, payload) {
    store.commit('setInvoiceInfo', payload[0]);
  },
  setInvoiceDetails(store, payload) {
    store.commit('setInvoiceDetails', payload);
  },
  setExchangeRate(store, payload) {
    store.commit('setExchangeRate', Number(payload[0].rate.toFixed(1)));
  },
};
