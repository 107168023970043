import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      helloworld: {
        inputs: {
          name: {
            label: 'Name',
            required: true,
            rules: [
              (v) => !!v || 'Name is required',
              (v) => (v && v.length <= 10) || 'Name must be less than 10 characters',
            ],
            isText: true,
            colNum: 1,
          },
          email: {
            label: 'Email',
            required: true,
            rules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
            isText: true,
            colNum: 1,
          },
          item: {
            label: 'Item',
            required: true,
            items: [
              { id: 0, value: 'Item 1' },
              { id: 1, value: 'Item 2' },
              { id: 2, value: 'Item 3' },
              { id: 3, value: 'Item 4' },
            ],
            rules: [(v) => !!v || 'Item is required'],
            isSelectBox: true,
            colNum: 3,
          },
          checkbox: {
            label: 'Do you agree?',
            required: true,
            rules: [(v) => !!v || 'You must agree to continue!'],
            isCheckbox: true,
            colNum: 3,
          },
          dates: {
            label: 'Pick date range',
            required: true,
            rules: [(v) => !!v || 'You must agree to continue!'],
            isCalendar: true,
            colNum: 2,
          },
        },
        reactiveVar: {
          name: '',
          email: '',
          item: '',
          checkbox: false,
          dates: [],
        },
        desserts: [
          {
            id: 0,
            name: 'Frozen Yogurt',
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            iron: '1%',
            glutenfree: true,
            isSelected: false,
          },
          {
            id: 1,
            name: 'Ice cream sandwich',
            calories: 237,
            fat: 9.0,
            carbs: 37,
            protein: 4.3,
            iron: '1%',
            glutenfree: false,
            isSelected: false,
          },
          {
            id: 2,
            name: 'Eclair',
            calories: 262,
            fat: 16.0,
            carbs: 23,
            protein: 6.0,
            iron: '7%',
            glutenfree: false,
            isSelected: false,
          },
          {
            id: 3,
            name: 'Jelly bean',
            calories: 375,
            fat: 0.0,
            carbs: 94,
            protein: 0.0,
            iron: '0%',
            glutenfree: true,
            isSelected: false,
          },
          {
            id: 4,
            name: 'Lollipop',
            calories: 392,
            fat: 0.2,
            carbs: 98,
            protein: 0,
            iron: '2%',
            glutenfree: true,
            isSelected: false,
          },
          {
            id: 5,
            name: 'KitKat',
            calories: 518,
            fat: 26.0,
            carbs: 65,
            protein: 7,
            iron: '6%',
            glutenfree: false,
            isSelected: false,
          },
        ],
        headers: [
          {
            text: 'Dessert (100g serving)',
            sortable: false,
            value: 'name',
          },
          { text: 'Calories', value: 'calories' },
          { text: 'Fat (g)', value: 'fat' },
          { text: 'Carbs (g)', value: 'carbs' },
          { text: 'Protein (g)', value: 'protein' },
          { text: 'Iron (%)', value: 'iron' },
          { text: 'Gluten-Free', value: 'glutenfree' },
        ],
      },
    };
  },
  getters,
};
