import base from '../../../common/base';
export default {
  priorityShipItems: (state) => state.priorityShipItems,
  issueDateWeekPulldownItem: (state) => state.issueDateWeekPulldownItem,
  csvFlag: (state) => state.csvFlag,
  priorityShipItemsFiller: (state, getters) => {
    const priorityShipItemsFiller = [];
    getters.priorityShipItems.map((item) => {
      priorityShipItemsFiller.push({
        issue_date: base.getFormatDate(item.issue_date),
        member_no: item.member_no,
        member_name_ja: item.member_name_ja,
        matter_no_csv: item.matter_no_csv,
        total_quantity: item.total_quantity,
        total_weight: item.total_weight,
        priority: item.priority,
      });
    });
    return priorityShipItemsFiller;
  },
};
