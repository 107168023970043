// import axios from "axios";
// const base_url = process.env.VUE_APP_API_URL;
export default {
  setPackingStatusList(store, data) {
    store.commit('setStatusItems', data);
  },
  setUserKbnAction(store, payload) {
    store.commit('setUserKbnMutation', payload);
  },
};
