export default {
  setSuccess(state, payload) {
    state.success = payload;
  },
  setShowCancelBtn(state, payload) {
    state.showCancelBtn = payload;
  },
  setLoading(state, payload) {
    if (state.loading !== payload) state.loading = payload;
  },
  setUploadLoadingStatus(state, payload) {
    state.uploadLoading = payload;
  },
  setShowModal(state) {
    state.showModal = !state.showModal;
  },
  setClickedConfirm(state, clickedConfirm) {
    state.clickedConfirm = clickedConfirm;
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setModalTitle(state, title) {
    state.modalTitle = title;
  },
  setModalMessage(state, message) {
    state.modalMessage = message;
  },
  setModalSuccessBtnLabel(state, successBtnLabel) {
    state.modalSuccessBtnLabel = successBtnLabel;
  },
  setModalConfirmBtnLabel(state, confirmBtnLabel) {
    state.modalConfirmBtnLabel = confirmBtnLabel;
  },
  setModalCancelBtnLabel(state, cancelBtnLabel) {
    state.modalCancelBtnLabel = cancelBtnLabel;
  },
  resetModalContents(state) {
    state.error = false;
    state.modalTitle = null;
    state.modalMessage = null;
    state.modalConfirmBtnLabel = null;
    state.modalCancelBtnLabel = 'キャンセル';
  },
  setErrorMessage(state, error) {
    state.error = true;
    state.showModal = true;
    state.modalMessage =
      error.response && error.response.data && (error.response.data.message || error.response.data.errors)
        ? `${error.response.data.message || Object.values(error.response.data.errors).join('\n ')} `
        : error.message || error;
    state.modalConfirmBtnLabel = 'OK';
    // throw error;
  },
  setCustomErrorMessage(state, message) {
    state.error = true;
    state.showModal = true;
    state.modalTitle = 'エラー';
    state.modalMessage = message;
    state.modalConfirmBtnLabel = 'OK';
    // throw error;
  },
  setAccessDenied(state, httpCode) {
    state.httpCode = httpCode;
  },
  setShowInstruction(state, showInstruction) {
    state.showInstruction = showInstruction;
  },
  setShowCourierRule(state, showCourierRule) {
    state.showCourierRule = showCourierRule;
  },
  setSort({ sort }, sortCols) {
    const { path, sortBy, sortDesc } = sortCols;
    const [currentSort, currentDesc] = sort?.[path] ?? [[], []];
    if (sortBy) {
      sort[path] = [sortBy, currentDesc];
    }
    if (sortDesc) {
      sort[path] = [currentSort, sortDesc];
    }
  },
};
