// import base from "../../../common/base";
export default {
  setMinyukinAnkenItemsMutations(state, payload) {
    const minyukinAnkenItems = [];
    if (payload) {
      payload.map((item) => {
        minyukinAnkenItems.push(item);
      });
    }
    state.minyukinAnkenItems = minyukinAnkenItems;
  },
};
