import Payload from '../../../components/customs/payload';
export default {
  getDashboardItemsActions({ commit }, payload) {
    commit('setDashboardItems', payload);
  },
  // ダッシュボード定数マス取得
  async getConstantListDataActions({ commit, dispatch }) {
    const params = new Payload('common-endpoint', '', 'getConstantList', [['REGION']]).data;
    const data = await dispatch('api/callAPI', params, { root: true });
    commit('setRegionConstantsMutations', data);
  },
  // ダッシュボード国エリアサマリ取得
  async getRegionSummaryListActions({ dispatch }, payload) {
    const apiEndPoint = 'get-dashboard';
    const body = [
      payload.invoiceDate?.[0] !== '' ? payload.invoiceDate?.[0] : null,
      payload.invoiceDate?.[1] !== '' ? payload.invoiceDate?.[1] : null,
      payload.nyukinDate?.[0] !== '' ? payload.nyukinDate?.[0] : null,
      payload.nyukinDate?.[1] !== '' ? payload.nyukinDate?.[1] : null,
      payload.ankenNo !== '' ? payload.ankenNo : null,
      payload.shukkayoteiDate?.[0] !== '' ? payload.shukkayoteiDate?.[0] : null,
      payload.shukkayoteiDate?.[1] !== '' ? payload.shukkayoteiDate?.[1] : null,
      payload.shukkaDate?.[0] !== '' ? payload.shukkaDate?.[0] : null,
      payload.shukkaDate?.[1] !== '' ? payload.shukkaDate?.[1] : null,
    ];
    const vuexAction = 'dashboard/getDashboardItemsActions';
    await dispatch('api/callAPI', { apiEndPoint, body, vuexAction }, { root: true });
  },
  async exportDashboardCsvFileActions(store, payload) {
    const targetCsvPattern = {};
    store.getters.dashboardItems.map((item) => {
      if (item.checked) {
        targetCsvPattern[item.key] = 1;
      }
      item.details.map((elment) => {
        if (elment.checked) {
          targetCsvPattern[elment.key] = 1;
        }
      });
    });
    const params = new Payload(
      'export-dashboard-csv-file',
      '',
      '',
      Object.assign({}, payload, { targetCsvPattern: targetCsvPattern }),
    ).data;
    const res = await store.dispatch('api/callAPI', params, { root: true });
    window.location.href = res.url;
  },
};
