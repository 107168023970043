import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';

Vue.use(VueRouter);

const pathName = store.getters['defined/pathNames'];
// const user = store.getters["defined/user"];

const routes = [
  {
    path: '/',
    name: pathName.DASHBOARD,
    meta: { name: 'Dashboard' },
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
  },
  {
    path: '/konpomeisai',
    name: pathName.KONPOMEISAI,
    meta: { name: 'KonpoMeisai' },
    component: () => import(/* webpackChunkName: "KonpoMeisai" */ '../views/KonpoMeisai.vue'),
  },
  {
    path: '/seiyakuankenkakunin',
    name: pathName.SEIYAKU_ANKEN_KAKUNIN,
    meta: { name: 'SeiyakuAnkenKakunin', storeModule: 'seiyakuAnken' },
    component: () => import(/* webpackChunkName: "SeiyakuAnkenKakunin" */ '../views/SeiyakuAnkenKakunin.vue'),
  },
  {
    path: '/yuusenjunisakusei',
    name: pathName.YUUSENJUNI_SAKUSEI,
    meta: { name: 'YuusenJuniSakusei' },
    component: () => import(/* webpackChunkName: "YuusenJuniSakusei" */ '../views/YuusenJuniSakusei.vue'),
  },
  {
    path: '/shukkaankenkakutei',
    name: pathName.SHUKKA_ANKEN_KAKUTEI,
    meta: { name: 'ShukkaAnkenKakutei', storeModule: 'shukkaAnkenKakutei' },
    component: () => import(/* webpackChunkName: "ShukkaAnkenKakutei" */ '../views/ShukkaAnkenKakutei.vue'),
  },
  {
    path: '/shukkashoruisakusei',
    name: pathName.SHUKKA_SHORUI_SAKUSEI,
    meta: { name: 'ShukkaShoruiSakusei', storeModule: 'shukkaShoruiSakusei' },
    component: () => import(/* webpackChunkName: "ShukkaAnkenKakutei" */ '../views/ShukkaShoruiSakusei.vue'),
  },
  {
    path: '/shukkajunbihikiwatashi',
    name: pathName.SHUKKA_JUNBI_HIKIWATASHI,
    meta: {
      name: 'ShukkaJunbiHikiwatashi',
      storeModule: 'shukkaJunbiHikiWatashi',
    },
    component: () => import(/* webpackChunkName: "ShukkaAnkenKakutei" */ '../views/ShukkaJunbiHikiwatashi.vue'),
  },
  {
    path: '/shukkazumianken',
    name: pathName.SHUKKA_ZUMI_ANKEN,
    meta: { name: 'ShukkaZumiAnken' },
    component: () => import(/* webpackChunkName: "ShukkaAnkenKakutei" */ '../views/ShukkaZumiAnken.vue'),
  },
  {
    path: '/minyukinankenlist',
    name: pathName.MINYUKIN_ANKEN_LISTS,
    meta: { name: 'MinyukinAnkenLists' },
    component: () => import(/* webpackChunkName: "ShukkaAnkenKakutei" */ '../views/MinyukinAnkenLists.vue'),
  },
  {
    path: '/constantmaster',
    name: pathName.CONSTANT_MASTER,
    meta: { name: 'ConstantMaster' },
    component: () => import(/* webpackChunkName: "ConstantMaster" */ '../views/ConstantMaster.vue'),
  },
  {
    path: '/boxtypemaster',
    name: pathName.BOX_TYPE_MASTER,
    meta: { name: 'BoxTypeMaster' },
    component: () => import('../views/BoxTypeMaster.vue'),
  },

  {
    path: '/courierlimit',
    name: pathName.COURIER_LIMIT_MASTER,
    meta: { name: 'CourierLimitMaster' },
    component: () => import('../views/CourierLimitMaster.vue'),
  },
  {
    path: '/notfound',
    // name: pathName.NOT_FOUND_PAGE,
    meta: { name: 'NotFoundPage' },
    component: () => import(/* webpackChunkName: "NotFoundPage" */ '../views/NotFoundPage.vue'),
  },
  {
    path: '/accessDenied',
    component: () => import(/* webpackChunkName: "AccessDenied" */ '../views/AccessDenied.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(async (to, from, next) => {
  if (from.path === '/accessdenied' || store.getters['ui/httpCode'] === 503) {
    return false;
  } else {
    if (!routes.find((item) => item.path === to.path)) {
      store.dispatch('ui/setAccessDenied', 404, { root: true });
      router.push({ path: '/notfound' });
    } else {
      next();
    }
  }
});
router.afterEach(() => {
  store.dispatch('api/getSummary');
});

export default router;
